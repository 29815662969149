/*******************************
        User Overrides
*******************************/

td .ui.fluid.dropdown {
    height: 30px !important;
    min-height: 30px;
    padding: @selectionMiniPadding;
}

td .ui.selection.dropdown > .search.icon,
td .ui.selection.dropdown > .delete.icon,
td .ui.selection.dropdown > .dropdown.icon {
    padding: @selectionIconMiniPadding
}

.ui.selection > .text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    vertical-align: bottom;
}